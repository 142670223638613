'use client';
import { useDisclosure } from '@chakra-ui/hooks';
import { Text } from '@chakra-ui/layout';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/modal';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { ReactNode } from 'react';

import { ShoppingToolsMenu } from '@/services/menu/shoppingtools.service';
import { NewCar } from '@/types/new-cars/new-cars.types';

import { ReactComponent as BackIcon } from '../../../_icons/CkChevronLeft_White24.svg';
import { ReactComponent as MenuLinkIcon } from '../../../_icons/CkChevronRight.svg';

import './SubMenu.scss';

const ShoppingTools = dynamic(
  () => import('../megamenu/ShoppingTools').then((module) => module),
  {
    ssr: false,
  },
);

const FinancialServices = dynamic(
  () => import('../megamenu/FinancialServices').then((module) => module),
  {
    ssr: false,
  },
);

const Vehicles = dynamic(
  () => import('../megamenu/Vehicles').then((module) => module),
  {
    ssr: false,
  },
);

type Props = {
  text: string;
  icon?: ReactNode;
  newCars?: NewCar[];
  closeHamburger?: () => void;
  url?: string;
  selectedDealershipName?: string;
  shoppingToolsItems?: ShoppingToolsMenu | null;
};
export const SubMenu = ({
  text,
  icon,
  newCars,
  closeHamburger,
  url,
  selectedDealershipName,
  shoppingToolsItems,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return url ? (
    <Link
      onClick={closeHamburger}
      href={url}
      role="link"
      className="mobileMenuLink"
    >
      <div className="mobileMenuLinkText">
        {icon && <Text marginRight={3}>{icon}</Text>}
        <Text mb={0}>{text}</Text>
      </div>
    </Link>
  ) : (
    <>
      <Link
        role="link"
        className="mobileMenuLink"
        onClick={isOpen ? onClose : onOpen}
        href={''}
      >
        <div className="mobileMenuLinkText">
          {icon && <Text marginRight={3}>{icon}</Text>}
          <Text mb={0}>{text}</Text>
        </div>
        <button role="button" name="Menu link icon" className="mobileMenuIcon">
          <MenuLinkIcon width={28} height={28} />
        </button>
      </Link>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="full"
        variant="clickThrough"
        id="mobileDrawer"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            className="mobileDrawerClose"
            color={'white'}
            top={6}
            right={5}
            onClick={closeHamburger}
            role="button"
            name="closeHamburger"
          />
          <DrawerHeader
            border={0}
            paddingY={7}
            className="mobileDrawerHeader"
            onClick={isOpen ? onClose : onOpen}
          >
            <button role="button" name="Back Menu" className="backMenu">
              <BackIcon
                onClick={isOpen ? onClose : onOpen}
                className="backMenu"
              />
            </button>
            <Text className="menuItem" mb={0}>
              {text}
            </Text>
          </DrawerHeader>
          <DrawerBody className="mobileDrawerBody" border={0} padding={0}>
            {text === 'Vehicles' && (
              <Vehicles
                newCars={newCars ?? []}
                isMobile={true}
                selectedDealershipName={selectedDealershipName}
              />
            )}
            {text === 'Shopping Tools' && (
              <ShoppingTools
                shoppingToolsItems={shoppingToolsItems ?? null}
                isMobile={true}
                closeHamburger={closeHamburger}
              />
            )}
            {text === 'Financial Services' && (
              <FinancialServices isMobile={true} />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
